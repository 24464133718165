@import '~@clout-team/web-components/build/assets/styles/variables';
@import "./assets/fonst";

*, *::before, *::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $color-black;

  &:hover, &:active {
    color: $color-black;
  }
}

html {
  height: 100%;
  min-height: 100%;
}
body {
  height: 100%;
  min-height: 100%;
  position: relative;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  padding: 0;
  margin: 0;
}

.component-FilterBar-search {
  width: fit-content!important;
}

#space-root {
  height: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .page-layout{
    overflow: hidden;
  }

}

.scrollbar-default {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-button {
    height: 0;
  }

  &::-webkit-scrollbar-track {
    opacity: 0;
  }

  &::-webkit-scrollbar-track-piece {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    height: 35px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner, &::-webkit-resizer {
    background-color: var(--black);
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--black-60);
  }

  overflow: hidden;
  overflow-y: auto;
}

input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.create-task{
  position: fixed !important;
  bottom: 24px;
  right: 24px;
  z-index: 99;
}

.space-app{
  height: 100%;
  .page-layout-center{
    height: 100%;
    .space-viewundefined{
      height: 100%;
      display: flex;
      flex-direction: column;
      >.message-bar--absolute{
        bottom: 24px;
        margin: auto;
        left: 0;
        right: 0;
      }
      .project-overflow{
        display: flex;
        padding: 16px;
        flex-direction: column;
        height: calc(100% - 168px);

        .component-status-header{
          top: -10px;
        }

      }
    }
  }
}